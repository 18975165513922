// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".index_wrap_1s8vy{padding-top:1.6rem}.index_wrap_1s8vy .index_back_2cFMI{position:fixed;top:0;z-index:9999}.index_wrap_1s8vy .index_list_28WLO{padding:0 .85333rem;display:flex;justify-content:space-between;flex-wrap:wrap}.index_wrap_1s8vy .index_list_28WLO .van-list__error-text,.index_wrap_1s8vy .index_list_28WLO .van-list__finished-text,.index_wrap_1s8vy .index_list_28WLO .van-list__loading{width:100%}.index_wrap_1s8vy .index_list_28WLO .index_listItem_2AI6w{margin-bottom:.56rem;width:3.86667rem;height:3.86667rem;box-shadow:0 .50667rem .77333rem 0 rgba(0,0,0,.5);border-radius:.34667rem}", ""]);
// Exports
exports.locals = {
	"wrap": "index_wrap_1s8vy",
	"back": "index_back_2cFMI",
	"list": "index_list_28WLO",
	"listItem": "index_listItem_2AI6w"
};
module.exports = exports;
